import Search from "./Search";
import {connect} from "react-redux";
import CartIcon from "../Cart/CartIcon";
import {syncProducts} from "../../service";
import NewMenu from "../Menu/NewMenu";
import {ListBulletIcon} from "@heroicons/react/20/solid";
import React, {useEffect, useRef, useState} from "react";

function Header(props) {

    const [showMenu, setShowMenu] = useState(false);
    const wrapperRef = useRef(null);

    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        window.onscroll = function() {
            if (window.scrollY > 64) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
    }, []);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape' && showMenu) {
                event.preventDefault();
                setShowMenu(false);
            }
        };

        const clickHandler = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && showMenu) {
                event.preventDefault();
                setShowMenu(false);
            }
        };


        if (showMenu) {
            document.addEventListener('keydown', keyDownHandler);
            document.addEventListener('mousedown', clickHandler);
        }

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('mousedown', clickHandler);
        };
    }, [showMenu]);
    return (
        <div ref={wrapperRef} className={`bg-zinc-800 border-b-4 border-b-yellow ${sticky?"sticky z-50 top-0":""}`}>
            <div className={"container mx-auto flex gap-2 items-center justify-between p-2"}>

                <div className={"flex items-center justify-between text-left cursor-pointer p-2 text-white"}
                     onClick={() => {setShowMenu(!showMenu)
                     }}><span className={"sm:px-2"}><ListBulletIcon className={"h-6 w-6"}/></span> <span className={"hidden sm:block"}>Alle Kategorier</span>
                </div>

                <Search catalogId={props.catalogId}/>
                {/*<button*/}
                {/*    className={"bg-zinc-800 border p-2 rounded-full hidden md:flex text-sm text-white focus:outline-none focus:ring-2 hover:ring-2 focus:ring-offset-2 focus:ring-offset-zinc-800 focus:ring-white hover:ring-white flex items-center"}*/}
                {/*    onClick={(e) => {*/}
                {/*        e.preventDefault();*/}
                {/*        alert("sync is in progress now... Please wait.")*/}
                {/*        syncProducts(props.catalogId).then(response => {*/}
                {/*            alert("sync successful");*/}
                {/*        }).catch(err => {*/}
                {/*            console.error("error", err)*/}
                {/*            alert("sync ended with an error.")*/}
                {/*        })*/}
                {/*    }}*/}
                {/*>Sync Products*/}
                {/*</button>*/}
                <CartIcon/>
            </div>
            <div className={`container mx-auto ${!showMenu?"hidden":""}`}>
                <NewMenu nodes={props.catalog.catalogNodes ? props.catalog.catalogNodes : []} handleClick={setShowMenu}/>
            </div>
        </div>
    )
}

const mapStateToProps = ({catalog}) => {
    return {catalog: catalog}
}
export default connect(mapStateToProps, {})(Header)