import {connect} from "react-redux";
import {useState, useMemo, useEffect} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import debounce from "lodash.debounce";
import {searchService} from "../../service";
import {Link} from "react-router-dom";

function Search(props) {
    const [query, setQuery] = useState("")
    const [queryVal, setQueryVal] = useState("")
    const debouncedSearch = useMemo(() => debounce((query) => {
        setQueryVal(query)
    }, 300), [])
    useEffect(() => debouncedSearch(query), [query])
    const {searchResults} = useFetchSearchApi(props.catalogId,queryVal)
    const [categories,setCategories] = useState()
    const [products,setProducts] = useState()
    const [variantNo,setVariantNo] = useState()

    useEffect(()=>{
        if (searchResults){
            setCategories(searchResults.categories)
            setProducts(searchResults.products)
            if (searchResults.products)
                searchResults.products.forEach(product=>{
                    if (product.variants.length)
                        product.variants.forEach(variant=>{
                            if (variant.no.replaceAll(" ","") === queryVal.replaceAll(" ",""))
                                setVariantNo(variant.no);
                        })
                })
        }
    },[searchResults])

    function getNo(product){
        let no = product.no;
        if (product.variants.length)
            product.variants.forEach(variant=>{
                if (variant.no.replaceAll(" ","")===queryVal.replaceAll(" ",""))
                    no = variant.no;
            })
        return no;
    }

    function getSearchResults() {
        if (searchResults.categories&&searchResults.categories.length === 0 && searchResults.products&&searchResults.products.length === 0) {
            return <div className={"w-full p-2 flex items-center content-center justify-center"}>
                <div className={"text-lg font-bold p-2 border-b"}>
                    0 result(s) found.
                </div>
            </div>
        } else return <div className={"w-full flex gap-3"}>
            {categories&&<div className={"w-1/2 rounded shadow"}><span
                className={"text-lg p-2 font-medium text-gray-400"}> Categories ({categories.length})</span>
                <ul>
                    {categories.map(category => <li key={category.id}
                        className={"border-b hover:bg-gray-100"}>
                        <Link to={`/shop/category/${category.id}`} onClick={() => {
                            setQuery("")
                        }}>
                            <div className={"p-2"}>
                                {category.name}
                            </div>
                        </Link>
                    </li>)}
                </ul>
            </div>}
            {products&&<div className={"w-1/2 rounded shadow"}><span
                className={"text-lg p-2 font-medium text-gray-400"}>Products ({products.length})</span>
                <ul>
                    {products.map(product => <li key={ Math.random() + product.id}
                        className={"border-b hover:bg-gray-100"}>
                        <Link to={`/shop/product/${product.id}?variant=${getNo(product)}`} onClick={() => {
                            setQuery("")
                        }}>
                            <div className={"p-2 flex flex-col"}>
                                {product.name}
                                <span className={"text-xs text-gray-500"}>{getNo(product)}</span>
                            </div>
                        </Link>
                    </li>)}
                </ul>
            </div>}
        </div>
    }


    return (
        <div className="flex-1 flex flex-col h-full relative">
            <div className="w-full h-full flex md:ml-0 ">
                <label htmlFor="search-field" className="sr-only">
                    Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600 ">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-2"
                         aria-hidden="true">
                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true"/>
                    </div>
                    <input
                        id="search-field"
                        name="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent rounded text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search"
                        type="search"
                        value={query}
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
            </div>
            {query.trim().length > 0 &&
                <div className={"z-50 relative w-full h-0"}>
                    <div className={"z-50 w-full bg-white rounded shadow-2xl p-2"}>
                        {searchResults && getSearchResults()}
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = () => {
  return{}
}

const useFetchSearchApi = (catalogId,query) => {
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (query.trim()!=="") {
            searchService(catalogId,query).then(results => {
                if (results.status && results.status !== 200)
                    console.error("error")
                else
                    setSearchResults(results)
                // showLoading(false)
            }).catch(err => {
                console.error("error", err)
                // showLoading(false);
                // showErrorNotificationAlert(true)
            })
        }else setSearchResults(null)
    }, [catalogId,query])
    return {searchResults}
}

export default connect(mapStateToProps,{})(Search);