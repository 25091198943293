import {
    addProductToCartAction,
    ADD_PRODUCT_TO_CART,
    CHANGE_INIT_STATE,
    changeInitStateAction,
    FETCH_CATALOG,
    fetchCatalogAction,
    refreshCartAction,
    REFRESH_CART,
    loadKeycloakAction,
    authenticateAction,
    LOAD_KEYCLOAK,
    AUTHENTICATE,
    addRecentCartItemAction,
    ADD_RECENT_CART_ITEM,
    setUserInfoAction,
    SET_USER_INFO,
    showLoadingAction,
    SHOW_LOADING_STATE,
    setLoggedInUserAction,
    SET_LOGGED_IN_USER,
    setCRMPersonAction,
    SET_CRM_PERSON, setProductForQuoteAction, SET_PRODUCT_FOR_QUOTE
} from "../actions";
import {fetchCatalogService, getCrmPersonService, getLoggedInUserService} from "../service";
import {cacheCartInLocalStorage} from "../utils";

const initState = {
    init: false,
    showLoading:false,
    catalog: {},
    cart: [],
    keycloak:null,
    authenticated: false,
    recentCartItem:{},
    userInfo: {},
    crmPerson: {},
    loggedInUser: {},
    productForQuote: ""
}

export const changeInitState = (init) => {
    return (dispatch) => {
        dispatch(changeInitStateAction(init))
    }
}
export const setProductForQuote = (productCode) => {
    return (dispatch) => {
        dispatch(setProductForQuoteAction(productCode))
    }
}
export const showLoading = (show) => {
    return (dispatch) => {
        dispatch(showLoadingAction(show))
    }
}
export const fetchCatalog = (catalog) => {
    return (dispatch) => {
        dispatch(fetchCatalogAction(catalog))
    }
}

export const addProductToCart = (product, quantity) => {
    window.dataLayer.push({
        event: 'add_to_cart',
        event_category: 'ecommerce',
        product: product
    });
    return (dispatch, getState) => {
        const cartList = []
        let productInCartExists = false;
        getState().cart.forEach(cartProduct => {
            if (cartProduct.product.id === product.id) {
                productInCartExists = true;
                let p = {product}
                if (cartProduct.quantity + quantity <= product.stock || !product.stockControlled) {
                    p.quantity = cartProduct.quantity + quantity;
                    dispatch(addRecentCartItemAction(product));
                }
                else {
                    p.quantity = cartProduct.quantity;
                    alert("not enough stock");
                }
                cartList.push(p)
            } else
                cartList.push(cartProduct);
        })
        if (!productInCartExists) {
            let p = {product}
            p.quantity = quantity;
            if (p.quantity <= product.stock || !product.stockControlled) {
                cartList.push(p);
                dispatch(addRecentCartItemAction(product));
            }
            else
                alert("not enough stock")
        }
        cacheCartInLocalStorage(cartList)
        dispatch(addProductToCartAction(cartList))
    }
}

export const removeProductFromCart = (productId) => {

  return (dispatch, getState)=>{
      try {
          let product = getState().cart.find(item => item.product.id === productId);
          if (product)
              window.dataLayer.push({
                  event: 'remove_from_cart',
                  event_category: 'ecommerce',
                  product: product.product
              });
      } catch (e) {
          console.log("error in removing product from cart", e)
      }
      const updated = getState().cart.filter(item=>item.product.id!==productId);
      cacheCartInLocalStorage(updated);
      dispatch(addProductToCartAction(updated));
  }
}
export const changeProductQuantityInCart = (productId,quantity) => {
  return (dispatch, getState)=>{
      let updated;
      if (quantity>0)
        updated = getState().cart.map(item => {
          if(item.product.id !== productId)
              return item;
          else {
              let p = {...item};
              if (quantity <= p.product.stock)
                p.quantity = quantity;
              return p
          }
      });
      else updated = getState().cart.filter(item=>item.product.id!==productId);

      cacheCartInLocalStorage(updated);
      dispatch(addProductToCartAction(updated));
  }
}

export const addRecentCartItem=(product)=>{
    return(dispatch)=>{
        dispatch(addRecentCartItemAction(product));
    }
}

export const emptyCart=()=>{
    return (dispatch)=>{
        cacheCartInLocalStorage([])
        dispatch(refreshCartAction([]))
    }
}
export const reloadCart=(cart)=>{
    return (dispatch)=>{
        cacheCartInLocalStorage(cart)
        dispatch(refreshCartAction(cart))
    }
}

export const loadKeycloak=(keycloak)=>{
    return (dispatch)=>{
        dispatch(loadKeycloakAction(keycloak))
    }
}
export const authenticate=(authenticated)=>{
    return (dispatch)=>{
        dispatch(authenticateAction(authenticated))
    }
}

export const setUserInfo = (userInfo,admin) => {
  return (dispatch)=>{
      userInfo.admin = admin;
      dispatch(setUserInfoAction(userInfo));
      if (userInfo.id){
          getLoggedInUserService(userInfo.id).then(response=>{
              if (response.status && response.status!==200)
                  console.error("error in fetching user Info");
              else
                dispatch(setLoggedInUserAction(response))
          }).catch(error=>{
              console.error("could not fetch logged in user",error);
          })
      }
      if (userInfo.email){
          getCrmPersonService(userInfo.id,userInfo.email).then(response=>{
              if (response.status && response.status!==200)
                  console.error("error in fetching user Info");
              else
                dispatch(setCRMPersonAction(response))
          }).catch(error=>{
              console.error("could not fetch logged in user",error);
          })
      }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
    switch (action.type) {
        case CHANGE_INIT_STATE:
            return {...state, init: action.payload}
        case SHOW_LOADING_STATE:
            return {...state, showLoading: action.payload}
        case FETCH_CATALOG:
            return {...state, catalog: action.payload}
        case ADD_PRODUCT_TO_CART:
            return {...state, cart: action.payload}
        case REFRESH_CART:
            return {...state, cart: action.payload}
        case ADD_RECENT_CART_ITEM:
            return {...state, recentCartItem: action.payload}
        case LOAD_KEYCLOAK:
            return {...state, keycloak: action.payload}
        case AUTHENTICATE:
            return {...state, authenticated: action.payload}
        case SET_USER_INFO:
            return {...state, userInfo: action.payload}
        case SET_LOGGED_IN_USER:
            return {...state, loggedInUser: action.payload}
        case SET_CRM_PERSON:
            return {...state, crmPerson: action.payload}
        case SET_PRODUCT_FOR_QUOTE:
            return {...state, productForQuote: action.payload}
        default:
            return initState;
    }
}