import {getKeycloakToken, getShopId} from "../utils";

export function fetchCatalogService(shopId) {
    return fetch(`/public/${shopId}/getCatalog`)
}
export function fetchCatalogNodeService(catalogId,catalogNodeId,searchParams,loggedInUser,crmPerson) {
    let formData = new FormData();
    searchParams.forEach((value, key) =>
        formData.append(`${key}`, value))
    if (loggedInUser.id && crmPerson.crmPersonId)
        return postForm(`/public/${getShopId()}/getCatalogNode/${catalogId}/${catalogNodeId}/${crmPerson.crmPersonId}/${loggedInUser.id}`,formData,true)
    return postForm(`/public/${getShopId()}/getCatalogNode/${catalogId}/${catalogNodeId}`,formData,false)
}
export function fetchProductDetailsService(productId,userInfo,crmPerson) {
    if (userInfo.id && crmPerson.crmPersonId) {
        return secureFetch(`/public/${getShopId()}/getProduct/${productId}/${crmPerson.crmPersonId}/${userInfo.id}`)
    }
    return fetch(`/public/${getShopId()}/getProduct/${productId}`)
}

export function searchService(catalogId, query) {
    let formData = new FormData();
    formData.append("query",query);
    return postForm(`/public/${getShopId()}/search/${catalogId}`,formData,false);
}
export function getHighLightedProductsService(formData) {
    return postForm(`/public/${getShopId()}/getHighLightedProducts`,formData,false);
}

export function createOrderService(orderBody) {
    return post(`/public/${getShopId()}/createOrder`, orderBody, false);
}

export function sendContactUsEmailService(emailBody) {
    return postForm(`/public/${getShopId()}/sendContactUsEmail`,emailBody,false);
}

export function userSignUp(form) {
    return postForm(`/public/${getShopId()}/userSignUp`,form,false);
}

export function confirmB2BOrderService(orderId,userId) {
    return post(`/secure/confirmB2BOrder/${orderId}/${userId}`,false,true);
}
export function getAllOrdersService(userId) {
    return secureFetch(`/secure/getAllOrders/${userId}`);
}

export function getLoggedInUserService(userId) {
    return secureFetch(`/secure/getLoggedInUser/${userId}`);
}

export function getCrmPersonService(userId,email) {
    let form = new FormData();
    form.append("email",email);
    return postForm(`/secure/getCrmPerson/${userId}`,form,true);
}
export function getOrderService(orderId,token) {
    return post(`/public/${getShopId()}/getOrder/${orderId}`,token,false);
}

export function getShippingPriceService(orderId){
    return fetch(`/public/${getShopId()}/getShippingPrice/${orderId}`);
}

export function updateShippingMethodInOrderService(orderId,shippingMethod){
    return post(`/public/${getShopId()}/updateShippingMethodInOrder/${orderId}`,shippingMethod,false);
}

export function syncProducts(webShopId){
    return fetch(`/admin/sync/${webShopId}`);
}

//request.
function fetch(request) {
    let params = {credentials: 'same-origin',
        // headers: {'Authorization': 'Bearer ' + getKeycloakToken()}
    };
    return makeRequest(request,params);
}

function secureFetch(request) {
    let params = {credentials: 'same-origin',
        headers: {'Authorization': 'Bearer ' + getKeycloakToken()}
    };
    return makeRequest(request,params);
}

function getChallenge(){
    return fetch("");
}

function makeRequest(request,params){
    return new Promise((resolve, reject) => {
        window.fetch( `${process.env.REACT_APP_API_ENDPOINT}${request}`, params)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 0) {
                        throw Error("No internet Connection.");
                    } else if (response.status === 500) {
                        return response.json() // return the result of the inner promise, which is an error
                            .then((json) => {
                                if (json.redirectUrl) {
                                    window.location = json.redirectUrl;
                                    return null;
                                } else
                                    throw new Error(json);
                            });
                    } else if (response.status === 403) {
                        throw Error("403");
                    } else {
                        throw Error("Failed to fetch, Please try again.");
                    }
                } else {
                    return response.json();
                }
            })
            .then(resolve)
            .catch(reject);
    });
}

//post with form data
export function postForm(request, formData,secure) {
    let url = `${process.env.REACT_APP_API_ENDPOINT}${request}`
    let customHeaders = {
        // Authorization: 'Bearer ' + getKeycloakToken()
    }
    if (secure)
        customHeaders = {Authorization: 'Bearer ' + getKeycloakToken()}
    return new Promise((resolve, reject) => {
        window.fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: customHeaders,
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 0) {
                        throw Error("Sjekk om du er på internett og prøv igen");
                    } else if (response.status === 500) {
                        return response.json() // return the result of the inner promise, which is an error
                            .then((json) => {
                                throw new Error(json);
                            });
                        //console.log(response.json());
                    } else {
                        throw Error("Det er skjedd en feil, prøv igen.");
                    }
                } else {
                    if (response.status === 204)
                        return response;
                    else
                        return response.json()
                }
            })
            .then(resolve)
            .catch(reject);
    });
}

//post request.
function post(request, body,secure) {
    let customHeaders = {
        "Content-Type": "application/json",
        // Authorization: 'Bearer ' + getKeycloakToken(),
    }
    if (secure)
        customHeaders = {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + getKeycloakToken(),
        }
    let params = {
        credentials: 'same-origin', method: 'post', headers: customHeaders
    }
    if (body) params['body'] = JSON.stringify(body);
    return new Promise((resolve, reject) => {
        window.fetch(`${process.env.REACT_APP_API_ENDPOINT}${request}`, params)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 0) {
                        throw Error("No internet Connection.");
                    } else if (response.status === 500) {
                        return response // return the result of the inner promise, which is an error

                    } else if (response.status === 403) {
                        throw Error("403");
                    } else {
                        throw Error("Failed to fetch, Please try again.");
                    }
                } else {
                    if (response.status === 204)
                        return response;
                    else
                        return response.json()
                }
            })
            .then(resolve)
            .catch(reject);
    });
}