import Header from "../Header/Header";
import {connect} from "react-redux";
import CatalogView from "../Catalog/CatalogNodeView";

function Home(props) {
    return (<div className={"h-full flex-grow"}>

    </div>)
}
const mapStateToProps = () => {
    return{}
}
export default connect(mapStateToProps,{})(Home)