export default function CartIconSvg(props) {
    return (<div className={"h-10 flex"}> <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 4000 4000"
        style={{
            enableBackground: "new 0 0 4000 4000",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <style>{".st0{fill:#f0c148}"}</style>
        <path
            className="st0"
            style={{fill:"#f0c148"}}
            d="M1565.5 2650.19c-119.06 0-215.88 96.94-215.88 216 0 119.12 96.81 216 215.88 216 119.12 0 216-96.88 216-216 0-119.07-96.88-216-216-216zm0 292.75c-42.31 0-76.75-34.44-76.75-76.75 0-42.25 34.44-76.75 76.75-76.75 42.38 0 76.75 34.5 76.75 76.75 0 42.31-34.37 76.75-76.75 76.75zM2915.06 2650.19c-119.06 0-215.88 96.94-215.88 216 0 119.12 96.81 216 215.88 216 119.12 0 216-96.88 216-216 0-119.07-96.87-216-216-216zm0 292.75c-42.31 0-76.75-34.44-76.75-76.75 0-42.25 34.44-76.75 76.75-76.75 42.38 0 76.75 34.5 76.75 76.75 0 42.31-34.37 76.75-76.75 76.75z"
        />
        <path
            d="M2934.12 2515.56H1541.56c-62.44 0-74.94-26.38-74.94-69.06 0-47.38 53.62-60.12 66.75-62.56h1348.06c56.44 0 111-42.94 124.25-97.75l121.81-502.12c-34.94 27.25-73.19 50.31-114.31 68.38l-24.31 100.38h-353.06l10.31-79.56c-31.75-9.75-62-22.5-90.88-37.75l-15.19 117.31h-607.12l-37.75-276.88H2372c-21.38-29.5-39.88-61.25-54.81-95h-435l-32.69-240.31 423 .62c1.56-33.06 6-65.31 13.31-96.44h-974.5l-35.44-144.94c-13.38-54.81-68-97.69-124.44-97.69h-354c-26.12 0-47.44 21.19-47.44 47.44s21.31 47.5 47.44 47.5h354c12.94 0 29.19 12.81 32.25 25.31l288.69 1181.81c-48.56 20.81-100.62 64.12-100.62 142.25 0 102.69 63.44 163.94 169.81 163.94h1392.56c128.69 0 192.38 16.5 227.06 58.69a47.45 47.45 0 0 0 36.62 17.31c10.69 0 21.38-3.56 30.25-10.81 20.19-16.62 23.06-46.56 6.44-66.81-63.68-77.38-166.11-93.26-300.37-93.26zm-310.5-467.81h342.19l-52.44 216.12c-2.94 12.44-19.12 25.19-31.94 25.19H2592.5l31.12-241.31zm-95.74 0-31.12 241.31h-518l-32.94-241.31h582.06zm-677.82 0 32.94 241.31h-316.62l-58.94-241.31h342.62zm-515.56-707.94 419.06.69 32.81 240.44H1393.5l-59-241.13zm82.19 336.13h382.69l37.75 276.88h-352.88l-67.56-276.88z"
            style={{
                fill: "#fff",
            }}
        />
        <path
            className="st0"
            style={{fill:"#f0c148"}}
            d="M2801.81 917.81c-247.62 0-448.25 200.75-448.25 448.31s200.62 448.31 448.25 448.31c247.56 0 448.19-200.75 448.19-448.31s-200.62-448.31-448.19-448.31zm-68.62 516.81"
        />
    </svg></div>)
}