import {Link} from "react-router-dom";
import Image from "../Shared/Image";
import ImageThumbnail from "../Shared/ImageThumbnail";
import {convertNumberToLocale} from "../../utils";

export default function ProductCard({product,reload,crmPerson}) {
    function showPriceForB2C(product) {
        if (product.hidePriceForB2C && Object.keys(crmPerson).length===0)
            return false;
        if (product.hidePriceForB2C && crmPerson && crmPerson.customerType==="B2B")
            return true;
        if (!product.hidePriceForB2C)
            return true;
    }
    return (
        <Link className={"hover:shadow-2xl duration-200 hover:-translate-y-2"} to={`/shop/product/${product.id}`} reloadDocument={reload}>
            <div key={product.id}
                 className={"group relative bg-white"}>
                <div className="flex items-center justify-center w-full rounded-md bg-gray-200 h-80">
                    {product.productImages.length>0?

                        <ImageThumbnail image={product.productImages[0]}/>:
                        <span
                            className={"flex items-center justify-center h-full w-full"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24"><title>image_not_supported</title><g
                                        fill="none">
                                        <path
                                            d="M21.9 21.9l-6.1-6.1-2.69-2.69L5 5 3.59 3.59 2.1 2.1.69 3.51 3 5.83V19c0 1.1.9 2 2 2h13.17l2.31 2.31 1.42-1.41zM5 19V7.83l6.84 6.84-.84 1.05L9 13l-3 4h8.17l2 2H5zM7.83 5l-2-2H19c1.1 0 2 .9 2 2v13.17l-2-2V5H7.83z"
                                            fill="#0e7490">

                                        </path></g>
                                    </svg>
                                </span>
                    }
                </div>

                <div className="mt-4 flex justify-between p-2">
                    <div>
                        <h3 className="text-sm text-gray-700">
                            <span aria-hidden="true" className="absolute inset-0" />
                                {product.name}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">{product.no}</p>
                        {product.stock>0 && <p className="mt-1 text-sm text-gray-500 flex items-center gap-2">På lager: <span>✅</span></p>}
                        {(product.stock < 1 && product.stockControlled!==null && product.stockControlled) &&<p className="mt-1 text-sm text-white flex"><span className={"bg-red-300 px-2 rounded"}>Utsolgt</span></p>}
                        {(product.price === 0 || (product.stock < 1 && product.stockControlled!==null && !product.stockControlled)) &&
                            <p className="mt-1 text-sm text-white flex">
                                <span className={"bg-blue-500 p-2 rounded"}>Bestillingsvare</span>
                            </p>}
                    </div>
                    {product.purchaseAbleOnWeb && showPriceForB2C(product) && product.price>0 &&
                        <p className="flex flex-col text-sm font-medium text-gray-900">
                            {product.discountedPrice>0&&<span className={"text-red-500"}>Kr {convertNumberToLocale(product.discountedPrice)}</span>}
                            <span className={`${product.discountedPrice>0?"line-through":""}`}>Kr {convertNumberToLocale(product.price)}</span>
                        </p>}
                </div>
            </div>
        </Link>
    )
}