import {connect} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {fetchCatalogNodeService} from "../../service";
import ProductCardLarge from "../Product/ProductCardLarge";
import Navigation from "./Navigation";
import ProductCard from "../Product/ProductCard";
import Pagination from "../Shared/Pagination";
import CatalogNodeCard from "./CatalogNodeCard";
import {addProductToCart} from "../../reducers";
import Filters from "../Shared/Filter/Filters";
import {ListBulletIcon, MagnifyingGlassIcon, Squares2X2Icon} from "@heroicons/react/24/solid";
import ProductRow from "../Product/ProductRow";
import debounce from "lodash.debounce";
import {getSortOrder, handleSorting} from "../../utils";
import SortDD from "../Shared/SortDD";

function CatalogNodeView(props) {
    let [searchParams, setSearchParams] = useSearchParams({});
    const [query, setQuery] = useState("")
    const [sort, setSort] = useState(-1);
    const debouncedSearch = useMemo(() => debounce((query) => {
        if (query.trim().length > 0) {
            searchParams.delete("name")
            searchParams.delete("no")
            searchParams.append("name", query.trim());
            searchParams.append("no", query.trim());
        } else {
            searchParams.delete("name")
            searchParams.delete("no")
        }
        setSearchParams(searchParams);
    }, 300), [query])

    useEffect(() => debouncedSearch(query), [query])

    const layoutProps = {list: "LIST", grid: "GRID"};
    const limit = 50;
    const [page, setPage] = useState(0);
    const params = useParams();
    const catalogNodeId = params.catalogNodeId;
    const {catalogNode} = useFetchCatalogNode(catalogNodeId, searchParams,props.userInfo,props.crmPerson);
    const [layout, setLayout] = useState(layoutProps.list)
    useEffect(() => {
        if (catalogNode && page !== 0)
            setPage(0)
    }, [catalogNode])

    function toggleLayout(layoutProp) {
        setLayout(layoutProp);
    }

    function getCatalogNodesComponent(catalogNodes) {
        return catalogNodes.map(node => <CatalogNodeCard key={node.id} node={node}/>)
    }

    function getProductList(products) {
        let sorted = products;
        if (sort>-1) {
            let so = getSortOrder(sort);
            sorted = handleSorting(products,so.on,so.order);
        }
        return <div className={"flex flex-col gap-1 pb-6"}>
            {sorted.map(product =>
                <ProductRow key={product.id} product={product} {...props} />
            )}
        </div>
    }

    function getProductCard(products) {
        let sorted = products;
        if (sort>-1) {
            let so = getSortOrder(sort);
            sorted = handleSorting(products,so.on,so.order);
        }
        return <div className={"grid grid-cols-1 gap-y-10 gap-x-6 pb-6 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8"}>
            {sorted.map(product => <ProductCard key={product.id} product={product} reload={false} crmPerson={props.crmPerson}/>
            )}
        </div>
    }

    function getProductCardLarge(products) {
        return <div
            className={"grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8"}>
            {products.map(product => <ProductCardLarge key={product.id} product={product}/>
            )}
        </div>
    }

    return (<div className={"flex flex-col gap-2"}>
        <div className={"w-full lg:container mx-auto justify-items-start pb-2"}>
            <Navigation key={`navigation-${catalogNodeId}`} navigation={catalogNode.navigation} current={catalogNode}/>
        </div>
        {catalogNode.catalogNodes && catalogNode.catalogNodes.length > 0 &&
            <div className={"flex flex-col lg:flex-row lg:gap-6"}>
                {catalogNode.filters && catalogNode.filters.length>0&&<div className={"w-full lg:w-1/5"}></div>}
            <div className={`flex flex-col lg:container w-full px-2 ${catalogNode.filters && catalogNode.filters.length===0?"mx-auto":""}`}>
                <div
                    className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">{getCatalogNodesComponent(catalogNode.catalogNodes)}</div>
            </div>
            </div>
        }
        <div className={"flex flex-col lg:flex-row gap-6 lg:divide-x"}>
            {catalogNode.filters && catalogNode.filters.length>0&&
                <div className={"w-full lg:w-1/5"}>
                <Filters key={`filters-${catalogNodeId}`} history={props.history}
                         filters={catalogNode.filters}
                         query={query} setQuery={setQuery}
                         searchParams={searchParams} setSearchParams={setSearchParams}
                />
            </div>
            }
            <div className={`flex flex-col lg:container ${catalogNode.filters && catalogNode.filters.length===0?"mx-auto":""} px-4`}>
                {((catalogNode.products && catalogNode.products.length > 0) || query.length>0)  &&
                <h1 className="py-2 text-xl text-black font-medium">Produkter</h1>}
                {/*search query*/}
                {((catalogNode.products && catalogNode.products.length > 0) || query.length>0) &&
                <div className="flex flex-col sm:flex-row justify-between gap-2 py-4">
                    <div className={"w-full sm:w-96"}>
                    <label htmlFor="search" className="sr-only block text-sm font-medium text-gray-700">
                        Quick search
                    </label>
                    <div className="relative mt-1 flex items-center">
                        <input
                            key={`search-in-${catalogNodeId}`}
                            type="search"
                            name="search"
                            id={`search-in-${catalogNodeId}`}
                            placeholder={"Søk på del i denne kategorien"}
                            value={query}
                            onChange={event => {
                                setQuery(event.target.value)
                            }}
                            className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm appearance-none "
                        />
                        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd
                                className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
                                <MagnifyingGlassIcon className={"h-4"}/>
                            </kbd>
                        </div>
                    </div>
                </div>
                    <div className="flex justify-end">
                        <div className="relative w-full inline-flex items-center justify-end">
                            <div className="flex items-center">
                                <div className={"px-4"}><SortDD current={sort} handleChange={setSort}/></div>
                                <button type="button"
                                        className={`group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 p-1 ${layout === layoutProps.list ? "bg-gray-300 shadow rounded" : ""}`}
                                        id="menu-button" aria-expanded="false" aria-haspopup="true"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleLayout(layoutProps.list)
                                        }}>
                                    <ListBulletIcon className={"h-8"}/>
                                </button>
                                <button type="button"
                                        className={`group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 p-1 ${layout === layoutProps.grid ? "bg-gray-300 shadow rounded" : ""}`}
                                        id="menu-button" aria-expanded="false" aria-haspopup="true"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleLayout(layoutProps.grid)
                                        }}>
                                    <Squares2X2Icon className={"h-8"}/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
                {catalogNode.products && catalogNode.products.length > 0 &&
                    <div>
                        {layout === layoutProps.list && getProductList(catalogNode.products.slice(page, page + limit))}
                        {layout === layoutProps.grid && getProductCard(catalogNode.products.slice(page, page + limit))}
                        {catalogNode.products.length > limit &&
                            <Pagination page={page} setPage={setPage} length={catalogNode.products.length}
                                        limit={limit}/>}
                    </div>
                }
            </div>
        </div>
    </div>)


}

const useFetchCatalogNode = (id, searchParams,userInfo,crmPerson) => {
    const [catalogNode, setCatalogNode] = useState({});
    useEffect(() => {
        fetchCatalogNodeService(process.env.REACT_APP_CATALOG, id, searchParams,userInfo,crmPerson).then(response => {
            if (response.status && response.status !== 200)
                console.error("error");
            else
                setCatalogNode(response);
        })
    }, [id, searchParams,crmPerson])
    return {catalogNode};
}

const mapStateToProps = ({catalog,userInfo,crmPerson}) => {
    return {catalog: catalog,userInfo:userInfo,crmPerson:crmPerson}
}
export default connect(mapStateToProps, {addProductToCart})(CatalogNodeView)