import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

export default function InputField({id,label,value,type,required,autocomplete,handleChange}) {
    return(
        <>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    type={type}
                    id={id}
                    name={id}
                    defaultValue={value}
                    onChange={(e)=>handleChange(e.target)}
                    autoComplete={autocomplete}
                    required={required}
                    className="peer block w-full rounded-md invalid:border-red-300 pr-10 invalid:text-red-900 invalid:placeholder-red-300 invalid:focus:border-red-500 focus:outline-none invalid:focus:ring-red-500 focus:ring-yellow focus:border-yellow sm:text-sm"
                />
                <div className="pointer-events-none absolute invisible peer-invalid:visible inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            </div>
        </>
    )
}