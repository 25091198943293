import InputField from "../Shared/InputField";
import {useEffect, useState} from "react";

export default function AddressForm({handleAddressChange,current}) {

    const [address,setAddress] = useState({
        address1:"",
        address2:"",
        city:"",
        postalArea:"",
        postalCode:"",
    })

    useEffect(()=>{
        if (current!=null)
            setAddress(current)
    },[current])

    function handleChange(input) {
        let add = {...address};
        add[input.id] = input.value;
        setAddress(add);
        handleAddressChange(add);
    }


    return (<>
        <div className="col-span-full">
            <InputField id={"address1"} label={"Adresselinje 1"} value={address.address1} type={"text"} autocomplete={"street-address"}
                        required={true} handleChange={handleChange}/>
        </div>
        <div className="col-span-full">
            <InputField id={"address2"} label={"Adresselinje 2"} value={address.address2} type={"text"} autocomplete={"street-address"}
                        required={false} handleChange={handleChange}/>
        </div>

        <div className="col-span-full sm:col-span-4">
            <InputField id={"postalCode"} label={"Postnummer"} value={address.postalCode} type={"text"} autocomplete={"postal-code"}
                        required={true} handleChange={handleChange}/>
        </div>

        <div className="col-span-full sm:col-span-4">
            <InputField id={"postalArea"} label={"Poststed"} value={address.postalArea} type={"text"} autocomplete={"address-level1"}
                        required={true} handleChange={handleChange}/>
        </div>

        <div className="col-span-full sm:col-span-4">
            <InputField id={"city"} label={"By"} value={address.city} type={"text"} autocomplete={"address-level2"}
                        handleChange={handleChange}/>
        </div>

    </>)
}