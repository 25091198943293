import React, {useEffect, useState} from 'react';

const CaptchaContainer = ({autotrigger = false, onToken,validHuman,service}) => {
    const [challenge, setChallenge] = useState('');
    const [captchaServerUrl, setCaptchaServerUrl] = useState(process.env.REACT_APP_CAPTCH_SERVER);

    useEffect(() => {
        setCaptchaServerUrl(service.getServerUrl());
        const fetchData = () => {
            service.getChallenge().then(response => {
                    setChallenge(response.str)
                    window.sqrCaptchaInit(autotrigger);
                }
            ).catch(err => {
                console.error("error in init captcha", err)
            });
        }

        if (!validHuman)
            fetchData();
        return () => {
            window.sqrCaptchaReset();
        };
    }, [validHuman]);


    useEffect(() => {
        window.setNonce = (n) => {
            service.setToken({challenge, nonce: n});
            onToken(true);
        };
    }, [challenge])


    return (
        <div
            className="captcha-container"
            data-sqr-captcha-url={captchaServerUrl}
            data-sqr-captcha-challenge={challenge}
            data-sqr-captcha-callback={"setNonce"}
        />
    );
};

export default CaptchaContainer;
