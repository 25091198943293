import {Fragment, useRef} from 'react'
import {connect} from "react-redux";
import {ShoppingBagIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {Popover, Transition} from '@headlessui/react'
import FadeInAnimation from "./FadeInAnimation";
import CartIconSvg from "../Shared/SVG/CartIconSvg";

function CartIcon(props) {
    const cartButton = useRef();
    return (
        <div className="flow-root px-2">
            <FadeInAnimation/>
            <Popover className="ml-4 flow-root text-sm lg:relative lg:ml-8">
                <Popover.Button ref={cartButton} className="group -m-2 flex items-center p-2">
                    <div className={"h-10"}><CartIconSvg/></div>
                    <span
                        className="text-sm font-medium text-white group-hover:text-gray-500">{props.cart.length}</span>
                    <span className="sr-only">items in cart, view bag</span>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Popover.Panel
                        className="absolute inset-x-0 z-50 top-16 mt-px bg-white pb-6 shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg">
                        <h2 className="sr-only">Shopping Cart</h2>

                        {props.cart.length > 0 && <div className="mx-auto max-w-2xl px-4">
                            <ul role="list" className="divide-y divide-gray-200 ">
                                {props.cart.slice(0,5).map((item) => (
                                    <li key={item.product.id} className="flex items-center py-6">
                                        {item.product.productImages.length > 0?<img
                                            src={item.product.productImages && item.product.productImages.length > 0 ? `${process.env.REACT_APP_API_ENDPOINT}/attachment/${item.product.productImages[0].id}/thumbnail` : ''}
                                            alt={item.product.no}
                                            className="h-16 w-16 flex-none rounded-md border border-gray-200 "
                                        />:<span
                                            className={"flex items-center justify-center bg-orange-100 h-14 w-24 rounded-full"}>
                                            <svg width="24" height="24" viewBox="0 0 24 24"><title>image_not_supported</title><g
                                            fill="none">
                                            <path
                                            d="M21.9 21.9l-6.1-6.1-2.69-2.69L5 5 3.59 3.59 2.1 2.1.69 3.51 3 5.83V19c0 1.1.9 2 2 2h13.17l2.31 2.31 1.42-1.41zM5 19V7.83l6.84 6.84-.84 1.05L9 13l-3 4h8.17l2 2H5zM7.83 5l-2-2H19c1.1 0 2 .9 2 2v13.17l-2-2V5H7.83z"
                                            fill="#0e7490">

                                            </path></g>
                                            </svg>
                                            </span>}
                                        <div className="ml-4 flex-auto">
                                            <h3 className="font-medium text-gray-900">
                                                <Link to={`/shop/product/${item.product.id}`} onClick={()=>{cartButton.current.click()}}>{item.product.name} <span className={"text-xs text-gray-500"}>(ant.: {item.quantity})</span></Link>
                                            </h3>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <Link
                                to={"/shop/checkout"}
                                onClick={()=>{cartButton.current.click()}}
                                className="w-full default-ag-button"
                            >
                                Til Betaling
                            </Link>

                            <p className="mt-6 text-center">
                                <Link to="/shop/cart"  onClick={()=>{cartButton.current.click()}} className="text-sm font-medium text-yellow hover:text-darkYellow hover:border-b hover:border-b-yellow">
                                    Se Handlekurven {props.cart.length>5 && <span>({props.cart.length})</span>}
                                </Link>
                            </p>
                        </div>}
                        {props.cart.length === 0 && <div className={"flex items-center justify-center p-2"}>
                            <span className={"text-base font-medium text-gray-900"}>0 items in cart</span>
                        </div>}
                    </Popover.Panel>
                </Transition>
            </Popover>

        </div>

    )
}

const mapStateToProps = ({cart}) => {
    return {cart: cart}
}

export default connect(mapStateToProps, {})(CartIcon)