import React from "react";

export default function Pagination({page, setPage, limit, length}) {
    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{page + 1}</span> to <span
                    className="font-medium">{page + limit <= length ? page + limit : length}</span> of{' '}
                    <span className="font-medium">{length}</span> results
                </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
                <button
                    disabled={page === 0}
                    onClick={() => {
                        if (page - limit >= 0)
                            setPage(page - limit)
                    }}
                    className={`${page === 0?"cursor-not-allowed":""} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                >
                    Previous
                </button>
                <button
                    disabled={page + limit > length}
                    onClick={() => {
                        if (page + limit < length)
                            setPage(page + limit)
                    }}
                    className={`${page + limit > length?"cursor-not-allowed":""} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
                >
                    Next
                </button>
            </div>
        </nav>
    )
}