import {Component} from "react";
import {connect} from "react-redux";
import {addRecentCartItem} from "../../reducers";


class FadeInAnimation extends Component{

    componentDidUpdate(prevProps,prevStatem,a) {
        const added  = this.props.recentCartItem;
        if (Object.keys(added).length>0 && prevProps.recentCartItem !== added) {
            const element = document.getElementById('cart-item');
            element.classList.remove("hidden")
            element.classList.add('animate__animated','animate__fadeInBottomLeft');
        }
    }

    handleAnimationEnd = () => {
        const element = document.getElementById('cart-item');
        element.classList.remove('animate__animated', 'animate__fadeInBottomLeft');
        element.classList.add("hidden")
        if (Object.keys(this.props.recentCartItem).length>0)
            this.props.addRecentCartItem({});
    }

    getProduct=(added)=>{
        if (Object.keys(added).length>0)
            return <div className={"flex items-center gap-2"}>
                <span className={"h-12 w-12 text-xs"}>
                    <img className={"cover bg-center"} src={added.productImages && added.productImages.length > 0 ?
                        `${process.env.REACT_APP_API_ENDPOINT}/attachment/${added.productImages[0].id}/thumbnail` : ''}
                         alt={added.no}/>
                </span>
                <span className={"text-sm"}>{added.name}</span>
            </div>
    }

    render() {
        if (Object.keys(this.props.recentCartItem).length>0)
        return (
            <div id="cart-item" className={"hidden absolute z-50 bg-white p-4 animate__faster rounded shadow"} onAnimationEnd={this.handleAnimationEnd}>
                {this.getProduct(this.props.recentCartItem)}
            </div>
        );
    }

}
const mapStateToProps = ({recentCartItem}) => {
  return{recentCartItem:recentCartItem}
}
export default connect(mapStateToProps,{addRecentCartItem})(FadeInAnimation);
