const KEYCLOAK_TOKEN_NAME = 'Anleggsdeler_Auth_Token'
const KEYCLOAK_PROFILE_NAME = 'Anleggsdeler_Auth_PROFILE'
const CART_KEY = "Anleggsdeler_Cart_Cache"
export const sortByName = (list) => {
  return list.sort((a,b)=>a.name.localeCompare(b.name,"nb"));
}
export const sortByNumber = (key,list) => {
  return list.sort((a,b)=>a[key]-b[key]);
}

export const getShopId = () => {
  return process.env.REACT_APP_WEBSHOP;
}

export const toLocalDateFormat = (date) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString("no-nb",options);
}
export const convertNumberToLocale = (number) => {
    if (number)
        return Number(number).toLocaleString("nb")
    else return 0;
}
export const toFixedNumber=(number,limit=2)=>{
    return Number(number).toLocaleString("nb");
}
export const getTotalWithVAT = (amount) => {
  let vat = Number(amount) * 0.25;
  return (Number(amount) + vat).toLocaleString("nb");
}

export const calculateVAT = (amount) => {
  return (Number(amount)*0.25).toLocaleString("nb");
}


export function getSortOrder(sort){
    switch (sort) {
        case 0:
            return {on:"name",order:"asc"}
        case 1:
            return {on:"name",order:"desc"}
        case 2:
            return {on:"price",order:"asc"}
        case 3:
            return {on:"price",order:"desc"}
        default:
            return {on:"name",order: "asc"}
    }
}

export const handleSorting = (tableData,sortField, sortOrder) => {
    if (sortField) {
        return [...tableData].sort((o1, o2) => {
            const a = o1[sortField];
            const b = o2[sortField];
            if (a === null) return 1;
            if (b === null) return -1;
            if (a === null && b === null) return 0;
                return (
                    a.toString().localeCompare(b.toString(), "nb", {
                        numeric: true,ignorePunctuation: true
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
        });
    }
    else
        return tableData;
};
export const getChildren=(parentId, categories)=> {
    const top = categories.filter(category => category.parentId === parentId)
    let cats = []
    top.forEach(cat => {
        cat.children = getChildren(cat.id,categories)
        cats.push(cat);
    })

    return cats;
}
export const isAuthorized = (roles,keycloak) => {
    if (keycloak && roles) {
        return roles.some(r => {
            const realm =  keycloak.hasRealmRole(r);
            const resource = keycloak.hasResourceRole(r);
            return realm || resource;
        });
    }
    return false;
}
export const storeKeycloakToken = (token,expires) => {
    let tokenObject = {token:token,expires:expires}
    localStorage.setItem(KEYCLOAK_TOKEN_NAME,JSON.stringify(tokenObject));
}
export const storeKeycloakProfile = (profile) => {
    localStorage.setItem(KEYCLOAK_PROFILE_NAME,JSON.stringify(profile));
}
export const getKeycloakToken = () => {
    let token =  JSON.parse(localStorage.getItem(KEYCLOAK_TOKEN_NAME));
    return token?token.token:""
}
export const getKeycloakProfile = () => {
    let profile =  JSON.parse(localStorage.getItem(KEYCLOAK_PROFILE_NAME));
    return profile
}
export const getKeycloakTokenExpires = () => {
    let token =  JSON.parse(localStorage.getItem(KEYCLOAK_TOKEN_NAME));
    return token?token.expires:0
}

export const cacheCartInLocalStorage = (cart) => {
  localStorage.setItem(CART_KEY,JSON.stringify(cart));
}
export const getCachedCartFromLocalStorage = () => {
  const cart = localStorage.getItem(CART_KEY);
  if (cart)
      return JSON.parse(cart);
  return [];
}
