import DisclosureCard from "./Disclosure/DisclosureCard";
import MultiColDisclosureCard from "./Disclosure/MultiColDisclosureCard";

export default function ProductAdditionalDetailsView({selectedProduct}) {

    function getDisclosure(card){
        switch (card.layout) {
            case "card-1":
                return <DisclosureCard details={card}/>;
            default:
                return <MultiColDisclosureCard details={card}/>;
        }
    }

    return(
        <section aria-labelledby="details-heading" className="mt-12 w-full">
            <h2 id="details-heading" className="sr-only">Additional details</h2>
            <div className="w-full">
                {selectedProduct.productCards.map((card) => (
                    <div key={card.id} className="divide-y divide-gray-200 border-t w-full">
                        {getDisclosure(card)}
                        {card.datafieldGroupingList.map(datafieldGrouping => (
                            <DisclosureCard details={datafieldGrouping}/>
                        ))}
                    </div>))}
            </div>
        </section>
    )
}