import {useState} from "react";

export default function FilterOptions({section, searchParams, setSearchParams}) {

    const [limit,setLimit] = useState(6);
    const locCompare = new Intl.Collator("nb", {numeric: true, sensitivity: 'base'});
    return (<div className="space-y-2">
        {section.values.sort(locCompare.compare).slice(0, limit).map((option, optionIdx) => (
            <div key={option} className="flex items-center">
                <label
                    htmlFor={`${section.id}-${optionIdx}-mobile`}
                    className="flex w-full py-2 items-center gap-2 text-sm text-gray-500 cursor-pointer"
                >
                    <input
                        id={`${section.id}-${optionIdx}-mobile`}
                        name={`${section.id}[]`}
                        defaultValue={option}
                        type="checkbox"
                        checked={searchParams.get(section.id) != null && searchParams.getAll(section.id).includes(option)}
                        onChange={(event) => {
                            if (!event.target.checked) {
                                let params = searchParams.getAll(section.id);
                                params = params.filter(p => p !== option);
                                searchParams.delete(section.id);
                                if (params.length > 0)
                                    params.forEach(p => searchParams.append(section.id, p))

                            } else
                                searchParams.append(section.id, option);
                            setSearchParams(searchParams)
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-darkYellow focus:ring-0"
                    />
                    {option}
                </label>
            </div>
        ))}
        {limit<section.values.length&&<button className={"w-full underline cursor-pointer"} onClick={(e) => {
            e.preventDefault();
            setLimit(6 + limit)
        }}>
            Vis mer
        </button>}
    </div>)
}