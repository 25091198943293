import {Link} from "react-router-dom";

export default function Navigation({navigation, current}) {
    return (
        <nav aria-label="Breadcrumb" className="">
            <div className="flex items-center space-x-4">
                <div className={"flex flex-row-reverse items-center gap-2"}>

                {navigation && navigation.length > 0 && navigation.map(nav =>
                    <div key={nav.id}>
                        <div className="flex items-center">
                            <Link to={`/shop/category/${nav.id}`} className="mr-4 text-sm font-medium text-gray-900">{nav.name}</Link>
                            <svg viewBox="0 0 6 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                 className="h-5 w-auto text-gray-300">
                                <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                )
                }
                </div>

                <div className="text-sm">
                    <span className="font-medium text-gray-500 hover:text-gray-600">{current.name}</span>
                </div>
            </div>
        </nav>
    )
}