import {connect} from "react-redux";

function Loading(props) {
    if (props.showLoading)
        return (
            <div className={"fixed flex justify-center items-center z-50 w-full h-full bg-gray-500 bg-opacity-75"}>
                <span
                    className="animate-spin relative inline-flex rounded-full h-8 w-8 border-2 border-t-sky-600"></span>
                <span className={"ml-2 text-white text-center animate-pulse"}>processing...</span>
            </div>
        )
    else return null
}

const mapStateToProps = ({showLoading}) => {
    return {
        showLoading: showLoading
    }
}
export default connect(mapStateToProps, {})(Loading)