import {useEffect, useState} from "react";
import {getHighLightedProductsService} from "../service";

export const useFetchHighlightedProducts = (filters) => {
    const [products, setProducts] = useState([]);
    useEffect(() => {
        let no = filters.split("no=")
        let formData = new FormData();
        no.forEach(n => {
            if (n.trim().length > 0)
                formData.append("no", n)
        })
        getHighLightedProductsService(formData).then(response => {
            if (response.status && response.status !== 200)
                console.log("error fetching highlighted products")
            else
                setProducts(response);
        }).catch(err => {
            console.error("[ERROR]", err);
        })
    }, [filters])
    return {products}
}