//type constants
export const CHANGE_INIT_STATE = "CHANGE_INIT_STATE";
export const SET_PRODUCT_FOR_QUOTE = "SET_PRODUCT_FOR_QUOTE";
export const FETCH_CATALOG = "FETCH_CATALOG";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const ADD_RECENT_CART_ITEM = "ADD_RECENT_CART_ITEM";
export const SHOW_LOADING_STATE = "SHOW_LOADING_STATE";
export const REFRESH_CART = "REFRESH_CART";
export const LOAD_KEYCLOAK = "LOAD_KEYCLOAK";
export const AUTHENTICATE = "AUTHENTICATE";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const SET_CRM_PERSON = "SET_CRM_PERSON";


//actions
export const changeInitStateAction = (init) => ({type: CHANGE_INIT_STATE, payload: init});
export const setProductForQuoteAction = (productCode) => ({type: SET_PRODUCT_FOR_QUOTE, payload: productCode});
export const fetchCatalogAction = (catalog) => ({type: FETCH_CATALOG, payload: catalog});
export const showLoadingAction = (show) => ({type: SHOW_LOADING_STATE, payload: show});
export const addProductToCartAction = (cartList) => ({type: ADD_PRODUCT_TO_CART, payload: cartList});
export const addRecentCartItemAction = (recentCartItem) => ({type: ADD_RECENT_CART_ITEM, payload: recentCartItem});
export const refreshCartAction = (cartList) => ({type: REFRESH_CART, payload: cartList});
export const loadKeycloakAction = (keycloak) => ({type: LOAD_KEYCLOAK, payload: keycloak});
export const authenticateAction = (authenticated) => ({type: AUTHENTICATE, payload: authenticated});
export const setUserInfoAction = (userInfo) => ({type: SET_USER_INFO, payload: userInfo});
export const setLoggedInUserAction = (loggedInUser) => ({type: SET_LOGGED_IN_USER, payload: loggedInUser});
export const setCRMPersonAction = (crmPerson) => ({type: SET_CRM_PERSON, payload: crmPerson});
