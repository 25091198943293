(function(window, document, undefined){

  const loadingSvg = "<svg class=\"loading-icon\" aria-hidden=\"true\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" height=\"32\" width=\"32\" viewBox=\"0 0 24 24\"><circle cx=\"12\" cy=\"12\" r=\"8\" stroke-width=\"3\" stroke-dasharray=\"15 10\" fill=\"none\" stroke-linecap=\"round\" transform=\"rotate(0 12 12)\"><animateTransform attributeName=\"transform\" type=\"rotate\" repeatCount=\"indefinite\" dur=\"0.9s\" values=\"0 12 12;360 12 12\"></animateTransform></circle></svg>";
  const successText = "I'm human."
  const loadingText = "Verifying human..";

  const numberOfWebWorkersToCreate = 4;

  window.sqrCaptchaReset = () => {
    window.sqrCaptchaInitDone = false;
  };
  //init headless
  window.sqrHeadlessCaptchaInit = (challenge, url, callback) => {
    if(window.sqrCaptchaInitDone) {
      console.error("Captcha is already initialized!");
      return
    }
    window.sqrCaptchaInitDone = true;

    const challengesMap = {};
    let headless = false;

    let proofOfWorker = { postMessage: () => console.error("error: proofOfWorker was never loaded. ") };


    if(!url) {
      console.error('url is missing')
    } else {
      if(url.endsWith("/")) {
        url = url.substring(0, url.length-1)
      }
      if (url.startsWith("/")) {
        url = document.location.protocol + '//' + document.location.host + url;
      }
    }

    window.sqrStartHeadlessCaptcha = () => {
      if(!challengesMap[challenge]) {
        challengesMap[challenge] = {
          attempts: 0,
          startTime: new Date().getTime(),
        };
        headless = true;
        proofOfWorker.postMessage({challenge: challenge});
      }
    };


    if (!window.Worker) {
      console.error("error: webworker is not support");
      //todo
    }

    if(url) {
      const webWorkerUrlWhichIsProbablyCrossOrigin = `${url}/static/proofOfWorker.js`;
      const webWorkerPointerDataURL = URL.createObjectURL(
        new Blob(
          [ `importScripts( "${ webWorkerUrlWhichIsProbablyCrossOrigin }" );` ],
          { type: "text/javascript" }
        )
      );

      let webWorkers;
      webWorkers = [...Array(numberOfWebWorkersToCreate)].map((_, i) => {
        const webWorker = new Worker(webWorkerPointerDataURL);
        webWorker.onmessage = function(e) {
          const challengeState = challengesMap[e.data.challenge]
          if(!challengeState) {
            console.error(`error: webworker sent message with unknown challenge '${e.data.challenge}'`);
          }
          if(e.data.type == "progress") {
            challengeState.difficulty = e.data.difficulty;
            challengeState.probabilityOfFailurePerAttempt = e.data.probabilityOfFailurePerAttempt;
            if(!challengeState.smallestHash || challengeState.smallestHash > e.data.smallestHash) {
              challengeState.smallestHash = e.data.smallestHash;
            }
            challengeState.attempts += e.data.attempts;
          } else if(e.data.type == "success") {
            if(!challengeState.done) {
              challengeState.done = true;
              challengeState.smallestHash = e.data.smallestHash;
              webWorkers.forEach(x => x.postMessage({stop: "STOP"}));

              if(!callback) {
                console.error(`error: data-sqr-captcha-callback '${e.dataset.sqrCaptchaCallback}' `
                  + "is not defined in the global namespace!");
              } else {
                //console.log(`firing callback for challenge ${e.data.challenge} w/ nonce ${e.data.nonce}, smallestHash: ${e.data.smallestHash}, difficulty: ${e.data.difficulty}`);
                callback(e.data.nonce, e.data.challenge[0]);
              }
            } else {
              //console.log("success recieved twice");
            }
          } else if(e.data.type == "error") {
            console.error(`error: webworker errored out: '${e.data.message}'`);
          } else {
            console.error(`error: webworker sent message with unknown type '${e.data.type}'`);
          }
        };
        return webWorker;
      });

      URL.revokeObjectURL(webWorkerPointerDataURL);

      proofOfWorker = {
        postMessage: arg => webWorkers.forEach((x, i) => {
          x.postMessage({ ...arg, workerId: i })
        })
      };

      window.sqrCaptchaReset = () => {
        window.sqrCaptchaInitDone = false;
        webWorkers.forEach(x => x.terminate());
      };
    }
  };



  window.sqrCaptchaInit = (autotrigger) => {
    if(window.sqrCaptchaInitDone) {
      console.error("sqrCaptchaInit was called twice!");
      return
    }
    console.log("init")
    window.sqrCaptchaInitDone = true;


    const challenges = Array.from(document.querySelectorAll("[data-sqr-captcha-challenge]"));
    const challengesMap = {};

    let url = null;
    let proofOfWorker = { postMessage: () => console.error("error: proofOfWorker was never loaded. ") };

    challenges.forEach(element => {
      if(!url) {
        if(!element.dataset.sqrCaptchaUrl) {
          console.error("error: element with data-sqr-captcha-challenge property is missing the data-sqr-captcha-url property");
        }
        url = element.dataset.sqrCaptchaUrl;
        if(url.endsWith("/")) {
          url = url.substring(0, url.length-1)
        }
        if (url.startsWith("/")) {
          url = document.location.protocol + '//' + document.location.host + url;
        }
      }
      if(!element.dataset.sqrCaptchaCallback) {
        console.error("error: element with data-sqr-captcha-challenge property is missing the data-sqr-captcha-callback property");
        return
      }

      if(typeof element.dataset.sqrCaptchaCallback != "string") {
        console.error("error: data-sqr-captcha-callback property should be of type 'string'");
        return
      }

      const callback = getCallbackFromGlobalNamespace(element.dataset.sqrCaptchaCallback);
      if(!callback) {
        console.warn(`warning: data-sqr-captcha-callback '${element.dataset.sqrCaptchaCallback}' `
                     + "is not defined in the global namespace yet. It had better be defined by the time it's called!");
      }


      let form = null;
      let parent = element.parentElement;
      let sanity = 1000;
      while(parent && !form && sanity > 0) {
        sanity--;
        if(parent.tagName.toLowerCase() == "form") {
          form = parent
        }
        parent = parent.parentElement
      }
      window.sqrCaptchaTrigger = () => {

        const challenge = element.dataset.sqrCaptchaChallenge;
        console.log('df', challenge);
        if(!challengesMap[challenge]) {
          challengesMap[challenge] = {
            element: element,
            attempts: 0,
            startTime: new Date().getTime(),
          };
          const progressBarContainer = element.querySelector(".sqr-captcha-progress-bar-container");
          progressBarContainer.style.display = "block";
          const mainElement = element.querySelector(".sqr-captcha");
          mainElement.style.display = "inline-block";
          const gears = element.querySelector(".sqr-gears-icon");
          gears.style.display = "block";

          challengesMap[challenge].updateProgressInterval = setInterval(() => {
            // calculate the probability of finding a valid nonce after n tries
            if(challengesMap[challenge].probabilityOfFailurePerAttempt && !challengesMap[challenge].done) {
              const probabilityOfSuccessSoFar = 1-Math.pow(
                challengesMap[challenge].probabilityOfFailurePerAttempt,
                challengesMap[challenge].attempts
              );
              const element = challengesMap[challenge].element;
              const progressBar = element.querySelector(".sqr-captcha-progress-bar");
              progressBar.style.width = `${probabilityOfSuccessSoFar*100}%`;
            }
          }, 500);


          proofOfWorker.postMessage({challenge: challenge});
        }
      };

      let cssIsAlreadyLoaded = document.querySelector(`link[href='/static/captcha.css']`);
      cssIsAlreadyLoaded = cssIsAlreadyLoaded || Array.from(document.styleSheets).some(x => {
        try {
          return Array.from(x.rules).some(x => x.selectorText == ".sqr-captcha")
        } catch (err) {
          return false
        }
      });

      if(!cssIsAlreadyLoaded) {
        const stylesheet = createElement(document.head, "link", {
          "rel": "stylesheet",
          "charset": "utf8",
        });
        stylesheet.onload = () => {
          renderCaptcha(element);
          if (autotrigger) {
            window.sqrCaptchaTrigger();
          }
        }
        // stylesheet.setAttribute("href", `/assets/captcha/static/captcha.css`);
      } else {
        renderCaptcha(element);
        if (autotrigger) {
          window.sqrCaptchaTrigger();
        }
      }

      if (form) {
        const inputElements = Array.from(form.querySelectorAll("input"))
          .concat(Array.from(form.querySelectorAll("textarea")));

        inputElements.forEach(inputElement => {
          inputElement.onchange = () => window.sqrCaptchaTrigger();
          inputElement.onkeydown = () => window.sqrCaptchaTrigger();
        });
      }
    });

    if (!window.Worker) {
      console.error("error: webworker is not support");
      //todo
    }

    if(url) {

      // https://stackoverflow.com/questions/21913673/execute-web-worker-from-different-origin/62914052#62914052
      const webWorkerUrlWhichIsProbablyCrossOrigin = `${url}/static/proofOfWorker.js`;

      const webWorkerPointerDataURL = URL.createObjectURL(
        new Blob(
          [ `importScripts( "${ webWorkerUrlWhichIsProbablyCrossOrigin }" );` ],
          { type: "text/javascript" }
        )
      );

      let webWorkers;
      webWorkers = [...Array(numberOfWebWorkersToCreate)].map((_, i) => {
        const webWorker = new Worker(webWorkerPointerDataURL);
        webWorker.onmessage = function(e) {
          const challengeState = challengesMap[e.data.challenge]
          if(!challengeState) {
            console.error(`error: webworker sent message with unknown challenge '${e.data.challenge}'`);
          }
          if(e.data.type === "progress") {
            challengeState.difficulty = e.data.difficulty;
            challengeState.probabilityOfFailurePerAttempt = e.data.probabilityOfFailurePerAttempt;
            if(!challengeState.smallestHash || challengeState.smallestHash > e.data.smallestHash) {
              challengeState.smallestHash = e.data.smallestHash;
            }
            challengeState.attempts += e.data.attempts;
          } else if(e.data.type === "success") {
            if(!challengeState.done) {
              challengeState.done = true;
              clearInterval(challengeState.updateProgressInterval);

              const element = challengeState.element;
              const progressBar = element.querySelector(".sqr-captcha-progress-bar");
              const checkmark = element.querySelector(".sqr-checkmark-icon");
              const gears = element.querySelector(".sqr-gears-icon");

              const description = element.querySelector(".sqr-captcha-description");
              challengeState.smallestHash = e.data.smallestHash;

              checkmark.style.display = "block";
              checkmark.style.animationPlayState = "running";
              gears.style.display = "none";
              progressBar.style.width = "100%";
              progressBar.classList.add("fadeOut")

              description.innerHTML = "";
              appendFragment(description, successText);

              webWorkers.forEach(x => x.postMessage({stop: "STOP"}));

              const callback = getCallbackFromGlobalNamespace(element.dataset.sqrCaptchaCallback);
              if(!callback) {
                console.error(`error: data-sqr-captcha-callback '${element.dataset.sqrCaptchaCallback}' `
                             + "is not defined in the global namespace!");
              } else {
                //console.log(`firing callback for challenge ${e.data.challenge} w/ nonce ${e.data.nonce}, smallestHash: ${e.data.smallestHash}, difficulty: ${e.data.difficulty}`);
                callback(e.data.nonce);
              }
            } else {
              //console.log("success recieved twice");
            }
          } else if(e.data.type === "error") {
            console.error(`error: webworker errored out: '${e.data.message}'`);
          } else {
            console.error(`error: webworker sent message with unknown type '${e.data.type}'`);
          }
        };
        return webWorker;
      });

      URL.revokeObjectURL(webWorkerPointerDataURL);

      proofOfWorker = {
        postMessage: arg => webWorkers.forEach((x, i) => {
          x.postMessage({ ...arg, workerId: i })
        })
      };

      window.sqrCaptchaReset = () => {
        window.sqrCaptchaInitDone = false;
        webWorkers.forEach(x => x.terminate());
      };
    }
  };

  const challenges = Array.from(document.querySelectorAll("[data-sqr-captcha-challenge]"));
  if(challenges.length) {
    window.sqrCaptchaInit();
  }

  function getCallbackFromGlobalNamespace(callbackString) {
    const callbackPath = callbackString.split(".");
    let context = window;
    callbackPath.forEach(pathElement => {
      if(!context[pathElement]) {
        return null;
      } else {
        context = context[pathElement];
      }
    });

    return context;
  }

  function getHashProgressText(challengeState) {
    const durationSeconds = ((new Date().getTime()) - challengeState.startTime)/1000;
    let hashesPerSecond = '[...]';
    if (durationSeconds > 1) {
      let hashesPerSecondFloat = challengeState.attempts / durationSeconds;
      hashesPerSecond = `[${leftPad(Math.round(hashesPerSecondFloat), 3)}h/s]`;
    }

    return `${hashesPerSecond} ..${challengeState.smallestHash} → ..${challengeState.difficulty}`;
  }

  function leftPad (str, max) {
    str = str.toString();
    return str.length < max ? leftPad(" " + str, max) : str;
  }

  function renderCaptcha(parent) {
    console.log("renderCaptcha")
    const svgXMLNS = "http://www.w3.org/2000/svg";
    const xmlnsXMLNS = 'http://www.w3.org/2000/xmlns/';
    const xmlSpaceXMLNS = 'http://www.w3.org/XML/1998/namespace';

    parent.innerHTML = "";

    const main = createElement(parent, "div", {"class": "sqr-captcha sqr-captcha-hidden"});
    const mainRow = createElement(main, "div", {"class": "sqr-captcha-row"});
    const mainColumn = createElement(mainRow, "div",{"class":"main-column"});
    const description = createElement(mainColumn, "div", {"class": "sqr-captcha-description"});
    appendFragment(description, loadingText);
    const progressBarContainer = createElement(mainColumn, "div", {
      "class": "sqr-captcha-progress-bar-container sqr-captcha-hidden"
    });
    createElement(progressBarContainer, "div", {"class": "sqr-captcha-progress-bar"});
    const iconContainer = createElement(mainRow, "div", {"class": "sqr-captcha-icon-container"});

    const checkmarkIcon = createElementNS(iconContainer, svgXMLNS, "svg", {
      "xmlns": [xmlnsXMLNS, svgXMLNS],
      "xml:space": [xmlSpaceXMLNS, 'preserve'],
      "version": "1.1",
      "viewBox": "0 0 24 24",
      "class": "sqr-checkmark-icon sqr-captcha-icon sqr-captcha-hidden"
    });
    createElementNS(checkmarkIcon, svgXMLNS, "path", {
      "class": "sqr-checkmark-icon-border",
      "d": "M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
    });


    const gearIcon = createElement(iconContainer,"div",null,"");
    gearIcon.classList.add("sqr-gears-icon")
    gearIcon.innerHTML = loadingSvg;
  }

  function createElementNS(parent, ns, tag, attr) {
    const element = document.createElementNS(ns, tag);
    if(attr) {
      Object.entries(attr).forEach(kv => {
        const value = kv[1];
        if((typeof value) == "string") {
          element.setAttributeNS(null, kv[0], kv[1])
        } else {
          element.setAttributeNS(value[0], kv[0], value[1])
        }

      });
    }
    parent.appendChild(element);
    return element;
  }

  function createElement(parent, tag, attr, textContent) {
    const element = document.createElement(tag);
    if(attr) {
      Object.entries(attr).forEach(kv => element.setAttribute(kv[0], kv[1]));
    }
    if(textContent) {
      element.textContent = textContent;
    }
    parent.appendChild(element);
    return element;
  }

  function appendFragment(parent, textContent) {
    const fragment = document.createDocumentFragment()
    fragment.textContent = textContent
    parent.appendChild(fragment)
  }

})(window, document);
