import {useState} from "react";

export default function Image({image}) {
    const [loaded,setLoaded] = useState(false)
    return(<div className={"flex relative justify-center h-full w-full"}>
        {!loaded&&<span
            className={"absolute z-10 animate-spin h-12 w-12 rounded-full border border-4 border-orange-400 border-t-green-900 top-1/2"}></span>}
        <img
            src={`${process.env.REACT_APP_API_ENDPOINT}/attachment/${image.id}`}
            alt={image.filename}
            onLoad={()=>setLoaded(true)}
            className="object-contain object-center sm:rounded-lg"
        />
    </div>)
}