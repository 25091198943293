import {Link} from "react-router-dom";
import React from "react";

export default function CatalogNodeCard({node}) {
    return <div key={node.id} className="group rounded shadow relative hover:shadow-2xl hover:-translate-y-2 duration-200">
        <div className="aspect-w-1 aspect-h-1 rounded-t overflow-hidden bg-gray-200 group-hover:opacity-75">
            {node.cardImage ?

                <img
                    src={node.cardImage ? `${process.env.REACT_APP_API_ENDPOINT}/attachment/${node.cardImage.id}/thumbnail` : ''}
                    alt={node.cardImage ? node.cardImage.name : ''}
                    className="h-full w-full object-cover object-center"
                />
                : <span
                    className={"flex items-center justify-center h-full w-full"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24"><title>image_not_supported</title><g
                                        fill="none">
                                        <path
                                            d="M21.9 21.9l-6.1-6.1-2.69-2.69L5 5 3.59 3.59 2.1 2.1.69 3.51 3 5.83V19c0 1.1.9 2 2 2h13.17l2.31 2.31 1.42-1.41zM5 19V7.83l6.84 6.84-.84 1.05L9 13l-3 4h8.17l2 2H5zM7.83 5l-2-2H19c1.1 0 2 .9 2 2v13.17l-2-2V5H7.83z"
                                            fill="#0e7490">

                                        </path></g>
                                    </svg>
                                </span>}
        </div>
        <div className="pt-5 pb-4 text-center">
            <h3 className="text-sm font-medium text-gray-900">
                <Link to={`/shop/category/${node.id}`}>
                    <span aria-hidden="true" className="absolute inset-0"/>
                    {node.name}
                </Link>
            </h3>
        </div>
    </div>
}