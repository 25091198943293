import Keycloak from 'keycloak-js';
import {useCallback, useEffect, useState} from "react";
import {storeKeycloakProfile, storeKeycloakToken} from "../../utils";
import * as React from "react";

export default function LoginButton({setKeycloak,setAuthenticated}) {

    const login = useCallback(() => {
        const keycloak = Keycloak({
            url: process.env.REACT_APP_KEYCLOAK_ENDPOINT,
            realm: process.env.REACT_APP_KEYCLOAK_REALM,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT
        });
        keycloak.init({onLoad:"login-required"}).then(authenticated => {
            setKeycloak(keycloak)
            setAuthenticated(authenticated)

        })
    }, [setAuthenticated, setKeycloak])


    return (<button type="button" className={"default-ag-button login-button"} onClick={(event) => {
        event.preventDefault();
        login()
    }}>
        Logg inn
    </button>)
}