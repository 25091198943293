import {Fragment, useEffect, useMemo, useState} from 'react'
import {Dialog, Disclosure, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {ChevronDownIcon, PlusIcon} from '@heroicons/react/20/solid'
import {useSearchParams} from "react-router-dom";
import FilterOptions from "./FilterOptions";
import debounce from "lodash.debounce";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {connect} from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Filters({filters,searchParams,setSearchParams,query,setQuery}) {
    // let [searchParams, setSearchParams] = useSearchParams({});
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    // const [query, setQuery] = useState("")



    // const debouncedSearch = useMemo(() => debounce((query) => {
    //     if (query.trim().length>0){
    //         searchParams.delete("name")
    //         searchParams.delete("no")
    //         searchParams.append("name", query.trim());
    //         searchParams.append("no", query.trim());
    //     }else {
    //         searchParams.delete("name")
    //         searchParams.delete("no")
    //     }
    //         setSearchParams(searchParams);
    // }, 300), [])
    //
    // useEffect(() => debouncedSearch(query), [query])


    function getFiltersList(filters) {
        if (filters)
        return filters.map((section) => (
            <Disclosure defaultOpen={true} as="div" key={section.name}
                        className="border-t border-gray-200 pt-4 pb-4">
                {({open}) => (
                    <fieldset>
                        <legend className="w-full px-2">
                            <Disclosure.Button
                                className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                                                            <span
                                                                className="text-sm font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex h-7 items-center">
                                  <ChevronDownIcon
                                      className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                      aria-hidden="true"
                                  />
                                </span>
                            </Disclosure.Button>
                        </legend>
                        <Disclosure.Panel className="px-4 pt-4 pb-2">
                            <FilterOptions section={section} searchParams={searchParams} setSearchParams={setSearchParams}/>
                        </Disclosure.Panel>
                    </fieldset>
                )}
            </Disclosure>
        ))
    }


    return (
        <>
            {/* Mobile filter dialog */}
            <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={setMobileFiltersOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                                <div className="flex items-center justify-between px-4">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setMobileFiltersOpen(false)
                                        }}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                {/* Filters */}
                                <form className="mt-4">
                                    <div
                                        className="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500 cursor-pointer"
                                        onClick={() => {
                                            searchParams.delete("*");
                                            setSearchParams(setSearchParams);
                                        }}>
                                        <span className="text-sm font-medium text-gray-900">Fjern alle</span>
                                        <span className="ml-6 flex h-7 items-center"><XMarkIcon
                                            className={'h-5 w-5 transform'} aria-hidden="true"/></span>
                                    </div>
                                    <input className={"block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-yellow focus:ring-yellow sm:text-sm my-2"} onChange={event => {setQuery(event.target.value)}} type="search" placeholder={"Vare nr. / Navn"} />
                                    {getFiltersList(filters)}
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className={"lg:hidden"}>
                <h2 className="sr-only">Filters</h2>

                <button
                    type="button"
                    className="inline-flex items-center"
                    onClick={() => setMobileFiltersOpen(true)}
                >
                    <span className="text-sm font-medium text-gray-700">Filters</span>
                    <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                </button>

            </div>

                <div className="hidden lg:block left-0 h-full">
                    <div
                        className="flex h-full w-full flex-col overflow-y-auto bg-white py-4 pb-6">
                        {/* Filters */}
                        <form className="mt-4">
                            <div
                                className="flex w-full items-center p-2 text-gray-400 hover:text-gray-500 cursor-pointer"
                                onClick={() => {
                                    searchParams.delete("*");
                                    setSearchParams({});
                                    setQuery("")
                                }}>
                                <span className="text-sm font-medium text-gray-900">Fjern alle</span>
                                <span className="ml-6 flex h-7 items-center">
                                    <XMarkIcon className={'h-5 w-5 transform'} aria-hidden="true"/>
                                </span>
                            </div>
                            {getFiltersList(filters)}
                        </form>
                    </div>
                </div>


        </>
    )
}
export default connect(null,{})(Filters)
