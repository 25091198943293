import React, {useEffect, useState} from "react";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/solid";
import {Link} from "react-router-dom";

export default function NewMenu({nodes, handleClick}) {
    const [subNodes, setSubNodes] = useState([]);
    const [expanded, setExpanded] = useState({});
    useEffect(() => {
        setSubNodes([]);
        setExpanded({})
    }, [nodes])


    return <div className={"bg-white !shadow-2xl z-50 absolute flex flex-col md:flex-row w-screen md:w-96"}>
        <div className={`flex flex-col gap-2 min-h-[20rem] w-full ${expanded.id?"hidden md:flex":""}`}>
            {nodes.map(node => <div key={node.id} className={"p-2 hover:bg-amber-50 flex justify-between gap-2"}>
                <span className={"flex w-full"}>
                    <Link className={"w-full"}
                          to={`/shop/category/${node.id}`}
                          onClick={() => {
                              handleClick(false)
                          }}>
                        {node.name}</Link>
                </span>
                {node.catalogNodes.length > 0 && expanded.id !== node.id ?
                    <span onClick={() => {
                        setSubNodes(node.catalogNodes)
                        setExpanded(node);
                    }}><PlusIcon className={"h-8 sm:h-6 cursor-pointer"}/></span> : (expanded.id === node.id ?
                        <span onClick={() => {
                            setSubNodes([])
                            setExpanded({})
                        }}>
                            <MinusIcon className={"h-8 sm:h-6 cursor-pointer"}/></span> : null)
                }
            </div>)}
        </div>

        <div
            className={`transition-all delay-100 ${subNodes.length > 0 ? "!translate-x-0" : ""} -translate-x-[100rem]`}>
            <div key={expanded.id} className={"p-2 bg-gray-100 hover:bg-amber-50 flex justify-between gap-2 md:hidden"}>
                <span className={"flex w-full"}>
                    <Link className={"w-full"}
                          to={`/shop/category/${expanded.id}`}
                          onClick={() => {
                              handleClick(false)
                          }}>
                        {expanded.name}</Link>
                </span>
                <span onClick={() => {
                    setSubNodes([])
                    setExpanded({})
                }}>
                            <MinusIcon className={"h-8 sm:h-6 cursor-pointer"}/></span>
            </div>
            {subNodes.length > 0 ? <NewMenu nodes={subNodes} handleClick={handleClick}/> : null}
        </div>
    </div>
}