import {CheckIcon} from "@heroicons/react/24/solid";
import {convertNumberToLocale} from "../../utils";

export default function ProductVariant({product, setSelectedProduct}) {
    return (
        <div key={product.id}
             onClick={() => {
                 setSelectedProduct(product)
             }}
             className={"group relative bg-white cursor-pointer"}>
            <div
                className="min-h-40 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-40">
                <img
                    src={product.productImages.length > 0 ? `${process.env.REACT_APP_API_ENDPOINT}/attachment/${product.productImages[0].id}/thumbnail` : ''}
                    alt="" className="h-full w-full object-cover object-center lg:h-full lg:w-full"/>
            </div>

            <div className="mt-4 flex justify-between">
                <div>
                    <h3 className="text-sm text-gray-700">
                        <span aria-hidden="true" className="absolute inset-0"/>
                        {product.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{product.no}</p>
                    {product.stock > 0 && <p className="mt-1 text-sm text-gray-500 flex items-center gap-2"><CheckIcon
                        className={"text-green-500 h-4 w-4"}/> På lager: <span>✅</span></p>}
                    {product.stock === 0 &&
                        <p className="mt-1 text-sm text-white flex"><span className={"bg-red-300 px-2 rounded"}>Utsolgt</span>
                        </p>}
                </div>
                {product.purchaseAbleOnWeb && <p className="text-sm font-medium text-gray-900">Kr {convertNumberToLocale(product.price)}</p>}
            </div>
        </div>
    )
}