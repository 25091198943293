import {Disclosure} from "@headlessui/react";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";

export default function MultiColDisclosureCard({details}) {
    return(
        <Disclosure as="div" defaultOpen={true} key={details.name}>
            {({open}) => (
                <div className={"flex flex-col w-full"}>
                    <h3>
                        <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                            <span className={open ? 'text-darkYellow' : 'text-gray-900 text-sm font-medium'}>{details.name}</span>
                            <span className="ml-6 flex items-center">
                                                    {open ? (
                                                            <MinusIcon className="block h-6 w-6 text-yellow group-hover:text-darkYellow" aria-hidden="true"/>) :
                                                        (<PlusIcon className="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />)
                                                    }</span>
                        </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel as="div" className="pb-6 w-full">
                        <div className={"flex flex-grow flex-wrap gap-y-2 w-full"}>
                            {details.datafieldList.filter(datafield=>datafield.value.join("").trim().length>0 && datafield.value.join("").trim().length<40).map((item) => (
                                <div key={item.id} className={"w-full lg:w-1/3"}>
                                    <div className={"flex flex-col text-left my-0 w-full"}>
                                        <div className={"text-xs font-medium text-gray-500"}>{item.name}</div>
                                        <div
                                            className={"px-2 text-lg w-full prose prose-lg prose-a:text-blue-300"}
                                            dangerouslySetInnerHTML={{__html: item.value.join(", ")}}></div>
                                    </div>
                                </div>
                            ))}
                            {details.datafieldList.filter(datafield=>datafield.value.join("").trim().length>0 && datafield.value.join("").trim().length>40).map((item) => (
                                <div key={item.id} className={"w-full"}>
                                    <div className={"flex flex-col text-left my-0 w-full"}>
                                        <div className={"text-xs font-medium text-gray-500"}>{item.name}</div>
                                        <div
                                            className={"px-2 text-lg w-full prose prose-a:text-blue-300"}
                                            dangerouslySetInnerHTML={{__html: item.value.join(", ")}}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </div>)}
        </Disclosure>
    )
}