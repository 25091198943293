import React from "react";
import {connect} from "react-redux";
import CatalogNodeCard from "./CatalogNodeCard";

function CatalogView(props) {
    if (props.catalog.id)
        return <div className="bg-white w-full lg:container mx-auto">
          <div className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8">
            <h2 className="sr-only">Alle</h2>

            <div className="-mx-px grid gap-3 md:gap-5 grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 py-2">
              {props.catalog.catalogNodes.map((node) => (
                  <CatalogNodeCard node={node}/>
              ))}
            </div>
          </div>
        </div>
}

const mapStateToProps = ({catalog}) => {
    return {catalog: catalog}
}
export default connect(mapStateToProps, {})(CatalogView)