import {DocumentIcon} from "@heroicons/react/24/solid";

export default function ProductAttachments({attachments}) {
    return <div className={"p-4 w-full"}><span className={"text-darkYellow"}>Attachments</span>
        <div className={"w-full flex flex-wrap gap-1"}>
            {attachments.map(attachment => <a className={"flex items-center gap-1"}
                                              href={`${process.env.REACT_APP_API_ENDPOINT}/attachment/${attachment.id}`}>
                <DocumentIcon className={"h-8 w-8 text-red-400"}/>
                <span className={"text-xs font-medium hover:text-yellow"}>{attachment.filename}</span></a>
            )}
        </div>
    </div>
}