import React from "react";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";

export default function BackButton() {
    const navigate = useNavigate();
    return (
        <div className={"flex pt-2"}>
            <span className={"rounded shadow border px-4 cursor-pointer text-sm"}
                  onClick={() => {
                      navigate(-1)
                  }}><ArrowLeftIcon className={"h-10 text-darkYellow"}/>
            </span>
        </div>
    )
}