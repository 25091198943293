import {Observable} from 'rxjs';
import {getShopId} from "../utils";

export default class CaptchaService {
    static captchaServerUrl = process.env.REACT_APP_CAPTCH_SERVER;
    static solvedToken = null;
    static observer = null;
    static preSolveObserver = null;

    static  {
        window.captchaCallback = this.captchaCallback.bind(this);
        window.preSolveCallback = this.preSolveCallback.bind(this);
    }

    static getToken() {
        return new Observable(observer => {
            if (this.solvedToken) {
                observer.next(this.solvedToken);
                observer.complete();
                this.solvedToken = null;
                window.sqrCaptchaReset();
                window.sqrCaptchaInit();
            } else {
                throw Error('Token not ready');
            }
        });
    }

    static getHeadlessToken() {
        return new Observable(observer => {
            if (this.solvedToken) {
                observer.next(this.solvedToken);
                observer.complete();
                this.solvedToken = null;
            } else {
                this.observer = observer;
                this.getChallenge().then(challenge => {
                    window.sqrHeadlessCaptchaInit([challenge.str], this.captchaServerUrl, window.captchaCallback);
                    window.sqrStartHeadlessCaptcha();
                })
            }
        });
    }

    static preSolveChallenge() {
        return new Observable(observer => {
            this.preSolveObserver = observer;
            this.getChallenge().then(challenge => {
                window.sqrHeadlessCaptchaInit([challenge.str], this.captchaServerUrl, window.preSolveCallback);
                window.sqrStartHeadlessCaptcha();
            })
        });
    }

    static setToken(token) {
        console.log("set token",token)
        this.solvedToken = token;
    }

    static preSolveCallback(nonce, challenge) {
        window.sqrCaptchaReset();
        this.solvedToken = {nonce: nonce, challenge: challenge};
        if (this.preSolveObserver) {
            this.preSolveObserver.next(true);
            this.preSolveObserver.complete();
        }
    }

    static captchaCallback(nonce, challenge) {
        window.sqrCaptchaReset();
        if (this.observer) {
            this.observer.next({nonce: nonce, challenge: challenge});
            this.observer.complete();
        }
    }

    static getChallenge() {
        return new Promise((resolve, reject) => {
                window.fetch(`/webshop/public/${getShopId()}/captchaChallenge`).then(
                    response => {
                        if (!response.ok) {
                            if (response.status === 0) {
                                throw Error("No internet Connection.");
                            } else if (response.status === 500) {
                                return response.json() // return the result of the inner promise, which is an error
                                    .then((json) => {
                                        if (json.redirectUrl) {
                                            window.location = json.redirectUrl;
                                            return null;
                                        } else
                                            throw new Error(json);
                                    });
                            } else if (response.status === 403) {
                                throw Error("403");
                            } else {
                                throw Error("Failed to fetch, Please try again.");
                            }
                        } else {
                            return response.json();
                        }
                    }
                ).then(resolve).catch(reject);
            }
        )
    }


    static getServerUrl() {
        return process.env.REACT_APP_CAPTCH_SERVER;
    }
}